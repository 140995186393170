import React from 'react';
import { I18n } from '../../i18n/I18n';

const ResetPasswordForm = React.lazy(() => import('./ResetPasswordForm'));

/**
 * Lazy loaded Reset password
 */
const LazyResetPasswordForm = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <ResetPasswordForm />
  </React.Suspense>
);

export default LazyResetPasswordForm;
