import React from 'react';
import { I18n } from '../../i18n/I18n';

const UserDashboard = React.lazy(() => import('./UserDashboard'));

/**
 * Lazy loaded user dashboard
 */
const LazyUserDashboard = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <UserDashboard />
  </React.Suspense>
);

export default LazyUserDashboard;
