import React from 'react';

import { I18n } from '../../i18n/I18n';

const ForgotPasswordForm = React.lazy(() => import('./ForgotPassword'));

/**
 * Lazy loaded sign up otp form
 */
const LazyForgotPasswordForm = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <ForgotPasswordForm />
  </React.Suspense>
);

export default LazyForgotPasswordForm;
