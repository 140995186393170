import React from 'react';
import { I18n } from '../../i18n/I18n';

const CourseInfo = React.lazy(() => import('./CourseInfo'));

/**
 * Lazy loaded course info
 */
const LazyCourseInfo = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <CourseInfo />
  </React.Suspense>
);

export default LazyCourseInfo;
