import React from 'react';
import { I18n } from '../../i18n/I18n';

const CourseGradebook = React.lazy(() => import('./CourseGradebook'));

/**
 * Lazy loaded course gradebook
 */
const LazyCourseGradebook = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <CourseGradebook />
  </React.Suspense>
);

export default LazyCourseGradebook;
