import React from 'react';
import { I18n } from '../../i18n/I18n';

const CookiePolicy = React.lazy(() => import('./CookiePolicy'));

/**
 * Lazy loaded cookie policy page
 */
const LazyCookiePolicy = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <CookiePolicy />
  </React.Suspense>
);

export default LazyCookiePolicy;
