const config = {
  COURSE_API: process.env.REACT_APP_COURSE_API,
  COURSE_API_URL: process.env.REACT_APP_COURSE_API_URL,
  LTI_API: process.env.REACT_APP_LTI_API,
  LTI_API_URL: process.env.REACT_APP_LTI_API_URL,
  CATALOG_API: process.env.REACT_APP_CATALOG_API,
  CATALOG_API_URL: process.env.REACT_APP_CATALOG_API_URL,
  STATIC_STUDY_CONTENT_API: process.env.REACT_APP_STATIC_STUDY_CONTENT_API,
  STATIC_STUDY_CONTENT_DISTRIBUTION:
    process.env.REACT_APP_STATIC_STUDY_CONTENT_DISTRIBUTION,
  REGION: process.env.REACT_APP_REGION,
  USER_POOL_ID: process.env.REACT_APP_USER_POOL_ID,
  USER_POLL_WEB_CLIENT_ID: process.env.REACT_APP_USER_POLL_WEB_CLIENT_ID,
  CAPTCHA_SITE_KEY: process.env.REACT_APP_CAPTCHA_SITE_KEY,
  APP_COOKIE_DOMAIN: process.env.REACT_APP_COOKIE_DOMAIN,
  ENABLE_REGISTRATION: process.env.REACT_APP_ENABLE_REGISTRATION,
  LOCALE: process.env.REACT_APP_LOCALE,
};

export default {
  ...config,
};
