import React from 'react';
import { I18n } from '../../i18n/I18n';

const SignUpForm = React.lazy(() => import('./SignUpForm'));

/**
 * Lazy loaded sign up form
 */
const LazySignUpForm = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <SignUpForm />
  </React.Suspense>
);

export default LazySignUpForm;
