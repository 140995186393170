import React from 'react';
import { Auth } from 'aws-amplify';
import { Redirect, Route, RouteProps } from 'react-router';
import { I18n } from './i18n/I18n';

interface PrivateRouteState {
  authorizationState: 'UNKNOWN' | 'AUTHORIZED' | 'UNAUTHORIZED';
}

/**
 * Qorunan routelar üçün məntiq
 */
export class PrivateRoute extends React.Component<RouteProps, PrivateRouteState> {
  constructor(props: any) {
    super(props);
    this.state = {
      authorizationState: 'UNKNOWN',
    };
  }

  async componentDidMount() {
    try {
      await Auth.currentSession();
      this.setState({
        authorizationState: 'AUTHORIZED',
      });
    } catch (err) {
      this.setState({
        authorizationState: 'UNAUTHORIZED',
      });
    }
  }

  render() {
    const { authorizationState } = this.state;

    if (authorizationState === 'AUTHORIZED') {
      return <Route {...this.props} />;
    }

    if (authorizationState === 'UNAUTHORIZED') {
      return <Redirect to="/signin" />;
    }

    return (
      <div
        style={{ height: '100vh' }}
        className="d-flex justify-content-center align-items-center">
        <h1>{I18n.t('status-authorizing')}</h1>
      </div>
    );
  }
}
