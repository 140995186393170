import React from 'react';
import { I18n } from '../../i18n/I18n';

const OTPForm = React.lazy(() => import('./OTPForm'));

/**
 * Lazy loaded sign up otp form
 */
const LazyOTPForm = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <OTPForm />
  </React.Suspense>
);

export default LazyOTPForm;
