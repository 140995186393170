import LocaleAz from './translations/az_AZ.json';
import LocaleEnUs from './translations/en_US.json';
import LocaleKo from './translations/ko_KR.json';

import Config from '../config';

interface LangFile {
  locale: string;
  localeShort: string;
  metadata: LangFileMetadata;
  resources: Record<string, Translation>;
}

interface LangFileMetadata {
  translators: string[];
  created: string;
  description: string;
}

interface Translation {
  translation: string;
  description: string;
}

const ENABLED_LOCALES = [LocaleAz, LocaleKo, LocaleEnUs];
const DEFAULT_LOCALE = LocaleAz.locale;

export enum LocaleLongNames {
  Azerbaijan = 'az_AZ',
  English = 'en_US',
  Korean = 'ko_KR',
}

export enum LocaleShortNames {
  Azerbaijan = 'az',
  English = 'en',
  Korean = 'ko',
}

/**
 * Internationalization class to support various languages.
 */
export class I18n {
  private static instance: I18n;
  private currentLocale: string;
  private dictionaries: Record<string, LangFile>;
  private dictionary: Record<string, Translation>;

  private constructor() {
    this.dictionaries = {};
    // TODO: Current locale needs to come from configuration.
    this.currentLocale = Config.LOCALE ? Config.LOCALE : DEFAULT_LOCALE;
    for (let i = 0; i < ENABLED_LOCALES.length; i++) {
      const locale = ENABLED_LOCALES[i];
      this.dictionaries[locale.locale] = locale;
    }

    this.dictionary = this.dictionaries[this.currentLocale].resources;
  }

  /**
   * Get instance of this singleton class.
   * @returns instance of the class.
   */
  public static getInstance(): I18n {
    if (I18n.instance) {
      return I18n.instance;
    }

    I18n.instance = new I18n();
    return I18n.instance;
  }

  /**
   * Returns locale name.
   * If short parameter is set to true the function will return the short
   * locale name. For example: for Azerbaijan (az_AZ) the short name will be 'az'.
   * @param short - if true returns short name for the locale.
   * @returns returns current locale name
   */
  public static getCurrentLocale(short = true): string {
    const locale = I18n.instance.dictionaries[I18n.instance.currentLocale];

    return short ? locale.localeShort : locale.locale;
  }

  /**
   * Return the translation string for a given string.
   * If the translation is not found it will return either
   * default value or the key itself.
   * @param key - translation key
   * @param _default - if not null it will be default value if translation is not found
   * @returns returns the translation or default string
   */
  public translate(key: string, _default: string | null = null): string {
    if (this.dictionary[key]) {
      return this.dictionary[key]['translation'];
    }

    return _default ? _default : key;
  }

  /**
   * Return the translation string for a given string.
   * If the translation is not found it will return either
   * default value or the key itself.
   * @param key - translation key
   * @param _default - if not null it will be default value if translation is not found
   * @returns returns the translation or default string
   */
  public static t(key: string, _default: string | null = null): string {
    return I18n.getInstance().translate(key, _default);
  }
}
