import React from 'react';
import { I18n } from '../../i18n/I18n';

const AccountSettings = React.lazy(() => import('./AccountSettings'));

/**
 * Lazy loaded account settings
 */
const LazyAccountSettings = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <AccountSettings />
  </React.Suspense>
);

export default LazyAccountSettings;
