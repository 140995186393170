import { Auth } from 'aws-amplify';
import Config from '../config.js';

async function custom_header() {
  const session = await Auth.currentSession();
  return {
    Authorization: `Bearer ${session.getIdToken().getJwtToken()}`,
  };
}

export const endpoints = [
  {
    name: Config.COURSE_API,
    endpoint: Config.COURSE_API_URL,
    region: Config.REGION,
    custom_header,
  },
  {
    name: Config.LTI_API,
    endpoint: Config.LTI_API_URL,
    region: Config.REGION,
    custom_header,
  },
  {
    name: Config.CATALOG_API,
    endpoint: Config.CATALOG_API_URL,
    region: Config.REGION,
    custom_header,
  },
  {
    name: Config.STATIC_STUDY_CONTENT_API,
    endpoint: Config.STATIC_STUDY_CONTENT_DISTRIBUTION,
    region: Config.REGION,
    custom_header,
  },
];
