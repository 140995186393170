import { Amplify } from 'aws-amplify';
import { endpoints } from './endpoints';
import Config from '../config.js';

const authConfig = {
  Auth: {
    region: Config.REGION,
    userPoolId: Config.USER_POOL_ID,
    userPoolWebClientId: Config.USER_POLL_WEB_CLIENT_ID,
  },
  API: {
    endpoints,
  },
};

Amplify.configure(authConfig);
