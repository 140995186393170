import React from 'react';
import { I18n } from '../../i18n/I18n';

const UserCertificates = React.lazy(() => import('./UserCertificates'));

/**
 * Lazy loaded course gradebook
 */
const LazyUserCertificates = () => (
  <React.Suspense fallback={<div>{I18n.t('page-certificates-loading')}</div>}>
    <UserCertificates />
  </React.Suspense>
);

export default LazyUserCertificates;
