import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';

import { PrivateRoute } from './PrivateRoute';
import { PublicRoute } from './PublicRoute';

import ChapterView from './pages/CourseChapter';
import CourseCatalog from './pages/CourseCatalog';
import CourseGradebook from './pages/CourseGradebook';
import CourseInfo from './pages/CourseInfo';
import CourseOverview from './pages/CourseOverview';
import UserDashboard from './pages/Dashboard';
import AccountSettings from './pages/AccountSettings';
import SecuritySettings from './pages/SecuritySettings';
import SignInForm from './pages/SingIn';
import SignUpForm from './pages/SignUp';
import OTPForm from './pages/SignUpOTP';
import UserCertificates from './pages/UserCertificates';
import ForgotPassword from './pages/ForgotPassword';
import ResetPasswordForm from './pages/ResetPassword';
import NotFoundPage from './pages/NotFound';
import CookiePolicy from './pages/CookiePolicy';
import PrivacyPolicy from './pages/PrivacyPolicy';
import TermsOfSevice from './pages/TermsOfService';

import 'react-toastify/dist/ReactToastify.css';
import './assets/bootstrap/bootstrap.scss';
import './assets/scss/style.scss';
import './resources/index.css';

/**
 * App bootstrap
 */
class App extends React.Component {
  render() {
    return (
      <>
        <ToastContainer />
        <BrowserRouter>
          <Switch>
            <PrivateRoute exact path="/">
              <UserDashboard />
            </PrivateRoute>
            <PrivateRoute
              path="/course/:courseId/chapter/:chapterId/:lessonId?/:topicId?"
              component={ChapterView}
            />
            <PrivateRoute
              path="/course/:courseId/gradebook"
              component={CourseGradebook}
            />
            <PrivateRoute path="/course/about/:courseId" component={CourseInfo} />
            <PrivateRoute path="/course/:courseId" component={CourseOverview} />
            <PrivateRoute path="/catalog" component={CourseCatalog} />
            <PrivateRoute path="/certificates" component={UserCertificates} />
            <PrivateRoute path="/settings/account" component={AccountSettings} />
            <PrivateRoute path="/settings/security" component={SecuritySettings} />
            <PublicRoute path="/signin" component={SignInForm} />
            <PublicRoute path="/signup" component={SignUpForm} />
            <PublicRoute path="/otp/:hash/:code?" component={OTPForm} />
            <Route path="/forgotpassword" component={ForgotPassword} />
            <Route path="/resetpassword/:hash" component={ResetPasswordForm} />
            <Route path="/cookies" component={CookiePolicy} />
            <Route path="/privacy" component={PrivacyPolicy} />
            <Route path="/terms" component={TermsOfSevice} />

            <Route component={NotFoundPage} />
          </Switch>
        </BrowserRouter>
      </>
    );
  }
}

export default App;
