import React from 'react';
import { I18n } from '../../i18n/I18n';

const SecuritySettings = React.lazy(() => import('./SecuritySettings'));

/**
 * Lazy loaded security settings
 */
const LazySecuritySettings = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <SecuritySettings />
  </React.Suspense>
);

export default LazySecuritySettings;
