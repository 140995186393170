import React from 'react';
import { I18n } from '../../i18n/I18n';

const CourseOverview = React.lazy(() => import('./CourseOverview'));

/**
 * Lazy loaded course overview
 */
const LazyCourseOverview = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <CourseOverview />
  </React.Suspense>
);

export default LazyCourseOverview;
