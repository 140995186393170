import React from 'react';
import { I18n } from '../../i18n/I18n';

const SignInForm = React.lazy(() => import('./SingIn'));

/**
 * Lazy loaded sign in form
 */
const LazySignInForm = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <SignInForm />
  </React.Suspense>
);

export default LazySignInForm;
