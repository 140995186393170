import React from 'react';
import { I18n } from '../../i18n/I18n';

const ChapterView = React.lazy(() => import('./ChapterView'));

/**
 * Lazy loaded chapter view
 */
const LazyChapterView = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <ChapterView />
  </React.Suspense>
);

export default LazyChapterView;
