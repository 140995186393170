import React from 'react';
import { I18n } from '../../i18n/I18n';

const PrivacyPolicy = React.lazy(() => import('./PrivacyPolicy'));

/**
 * Lazy loaded cookie policy page
 */
const LazyPrivacyPolicy = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <PrivacyPolicy />
  </React.Suspense>
);

export default LazyPrivacyPolicy;
