import React from 'react';
import { Redirect, Route } from 'react-router';
import { I18n } from './i18n/I18n';
import { PrivateRoute } from './PrivateRoute';

/**
 * Bu route ilə təyin edilən komponentlər public sayılır
 * login olmamış görmək olar, ancaq login olandan sonra görmək olmaz
 */
export class PublicRoute extends PrivateRoute {
  render() {
    const { authorizationState } = this.state;

    if (authorizationState === 'AUTHORIZED') {
      return <Redirect to="/" />;
    }

    if (authorizationState === 'UNAUTHORIZED') {
      return <Route {...this.props} />;
    }

    return (
      <div
        style={{ height: '100vh' }}
        className="d-flex justify-content-center align-items-center">
        <h1>{I18n.t('status-authorizing')}</h1>
      </div>
    );
  }
}
