import React from 'react';
import { I18n } from '../../i18n/I18n';

const CourseCatalog = React.lazy(() => import('./CourseCatalog'));

/**
 * Lazy loaded course catalog
 */
const LazyCourseCatalog = () => (
  <React.Suspense fallback={<div>{I18n.t('status-loading')}</div>}>
    <CourseCatalog />
  </React.Suspense>
);

export default LazyCourseCatalog;
