import React from 'react';

const TermsOfService = React.lazy(() => import('./TermsOfService'));

/**
 * Lazy loaded cookie policy page
 */
const LazyTermsOfService = () => (
  <React.Suspense fallback={<div>Yüklənir...</div>}>
    <TermsOfService />
  </React.Suspense>
);

export default LazyTermsOfService;
